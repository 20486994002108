<template>
  <div
    id="layer-switch-onlineMap"
    name="GenerateLayerSwitch"
    class="ol-control ol-layer-switch"
    :class="bottomCollapsed"
  >
    <div
      class="ol-layer-switch-title"
      style="
        text-align: center;
        border-bottom: 1px solid rgb(255, 255, 255);
        margin-bottom: 5px;
        font-weight: bold;
        padding: 2px;
      "
    >
      <div @click="toggled" style="cursor:pointer;"><span>Camadas</span><i class="fa posRight cursor-pointer" :class="'fa-chevron-' + arrowDirection"></i></div>
    </div>
    <div class="menu-toggle" :class="toggleMenu">
      <ul
        class="ol-layer-switch-ul"
        style="
          text-align: left;
          list-style: none;
          margin: 0px;
          padding: 0px;
          cursor: pointer;
        "
      >
        <li class="ol-layer-switch-li">
          <input
            id="streets"
            type="radio"
            name="radioonlineMap"
            checked="true"
            value="osm"
            v-on:click="clickEvent"
          /><label
            for="radioonlineMap"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;Ruas (OSM)</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="topography-world"
            type="radio"
            name="radioonlineMap"
            value="esri-topografia"
            v-on:click="clickEvent"
          /><label
            for="topography-world"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;Topografia Mundial (Esri)</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="national-geography"
            type="radio"
            name="radioonlineMap"
            value="esri-geografia"
            v-on:click="clickEvent"
          /><label
            for="national-geography"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;Geografia Nacional (Esri)</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="satellite"
            type="radio"
            name="radioonlineMap"
            value="esri-satelite"
            v-on:click="clickEvent"
          /><label
            for="satellite"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;Satélite (Esri)</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="biomas"
            type="checkbox"
            value="biomas"
            v-on:click="clickEvent"
          /><label
            for="biomas"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;Biomas</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="uc_federal"
            type="checkbox"
            value="uc_federal"
            v-on:click="clickEvent"
          /><label
            for="uc_federal"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;UC Federal</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="uc_estadual"
            type="checkbox"
            value="uc_estadual"
            v-on:click="clickEvent"
          /><label
            for="uc_estadual"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;UC Estadual</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="zee"
            type="checkbox"
            value="zee"
            v-on:click="clickEvent"
          /><label
            for="zee"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >&nbsp;ZEE</label
          >
        </li>
        <!-- ANTIGOS 
        <li class="ol-layer-switch-li">
          <input
            id="portalbio-utilizadas"
            type="checkbox"
            checked="true"
            value="layer-feature_17731"
            v-on:click="clickEvent"
          /><i
            class="fa fa-circle"
            aria-hidden="true"
            style="color: rgb(255, 255, 0); margin-left: 2px; margin-right: 5px"
          ></i
          ><label
            for="portalbio-utilizadas"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >Portalbio Utilizadas</label
          >
        </li>
        <li class="ol-layer-switch-li">
          <input
            id="salve-utilizadas"
            type="checkbox"
            checked="true"
            value="layer-feature_11961"
            v-on:click="clickEvent"
          /><i
            class="fa fa-circle"
            aria-hidden="true"
            style="color: rgb(0, 255, 0); margin-left: 2px; margin-right: 5px"
          ></i
          ><label
            for="salve-utilizadas"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >Salve Utilizadas</label
          >
        </li>
        <li
          class="ol-layer-switch-li"
          title="Ocorrências utilizadas na avaliação
  sem presença atual na coordenada."
        >
          <input
            id="registros-historicos"
            type="checkbox"
            checked="true"
            value="layer-feature_64441"
            v-on:click="clickEvent"
          /><img
            src="/img/open-layer-map/markers/point_green_cross_legend.png"
            alt="Registros Históricos (Salve)"
            style="
              margin-top: -4px;
              margin-left: 2px;
              margin-right: 5px;
              width: 14px;
              height: 14px;
            "
          /><label
            for="registros-historicos"
            style="padding-top: 0px; display: inline; cursor: pointer"
            >Registros Históricos (Salve)</label
          >
        </li>
        /ANTIGOS -->


        <li>
            <div class="registros_div"><span>Registros</span></div>
        </li>      

        <li class="ol-layer-switch-li"
            title="Registros utilizados na avaliação">
            <input
              id="regUtilizados"
              type="checkbox"
              checked="true"
              value="layer-feature_11961"
              v-on:click="clickEvent"
            />
            <img
              src="/salve/assets/markers/ponto_verde_12x12.png"
              style="
                margin-top: -4px;
                margin-left: 2px;
                margin-right: 5px;
                width: 14px;
                height: 14px;
              "
            />          
            <label
              for="regUtilizados"
              style="padding-top: 0px; display: inline; cursor: pointer"            
              >Utilizados</label
            >
          </li>
  
          <li
            class="ol-layer-switch-li"
            title="Registros sem presença atual na coordenada"
          >
            <input
              id="regUtilizadoHistorico"
              type="checkbox"
              checked="true"
              value="layer-feature_64441"
              v-on:click="clickEvent"
            />
            <img
              src="/salve/assets/markers/ponto_verde_12x12_T.png"
              style="
                margin-top: -4px;
                margin-left: 2px;
                margin-right: 5px;
                width: 14px;
                height: 14px;
              "
            />
            <label
              for="regUtilizadoHistorico"
              style="padding-top: 0px; display: inline; cursor: pointer"
              >Históricos</label
            >
          </li>
  
          
          <li class="ol-layer-switch-li"
            title="Registros estão confirmados mas foram adicionados após a avaliação">
            <input
              id="regConfirmadoAdicionadoAposAvaliacao"
              type="checkbox"
              checked="true"
              value="layer-feature_11961"
              v-on:click="clickEvent"
            />
            <img
              src="/salve/assets/markers/ponto_verde_12x12_X.png"
              style="
                margin-top: -4px;
                margin-left: 2px;
                margin-right: 5px;
                width: 14px;
                height: 14px;
              "
            />          
            <label
              for="regConfirmadoAdicionadoAposAvaliacao"
              style="padding-top: 0px; display: inline; cursor: pointer"
              >Confirmados e adicionados<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              após avaliação</label
            >
          </li>
          <li>
            <div class="registros_div"></div><br>
          </li>
   <!-- ---------------------------------------------------------->

      </ul>
      <hr style="padding: 1px; margin: 4px" />
      <button
        type="button"
        title="Salvar imagem do mapa atual no formato png."
        style="cursor: pointer; width: 100%; font-size: 14px"
        @click="clickEventPNG"
      >
        Exportar Mapa (png)
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleMenu: '',
      openMenu: true,
      arrowDirection: 'down',
      bottomCollapsed: ''
    }
  },
  methods: {
    clickEvent: function (event) {
      this.$emit("filter", event.currentTarget.getAttribute("id"));
    },
    clickEventPNG: function () {
      this.$emit("downloadPNG");
    },
    toggled() {
        if(this.openMenu == true) {
          this.openMenu = false
          this.toggleMenu = 'hidden'
          this.arrowDirection = 'up'
          this.bottomCollapsed = 'bottomCollapsed'
        } else {
          this.openMenu = true
          this.toggleMenu = ''
          this.arrowDirection = 'down'
          this.bottomCollapsed = ''
        }
    },
  },
};
</script>
<style lang="scss" scoped>
#layer-switch-onlineMap {
  white-space: nowrap;
  background-color: rgb(124, 153, 189);
  top: auto;
  bottom: 100px;
  left: auto;
  right: 0px;
  position: absolute;
  padding: 2px 8px 10px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  overflow: hidden;
  margin: 0px;
  opacity: 0.7;
  z-index: 999999999;
  width: 222px;
  .posRight {
    position: absolute;
    right: 5%;
  }
  .hidden {
    display:none !important;
  }
  .menu-toggle {
    display:block;
  }
  &.bottomCollapsed {
    bottom: 360px !important;
  }
}
@media only screen and (max-width: 600px) {
  #layer-switch-onlineMap {
    right: 0px !important;
    bottom: 150px;
  }
}
.registros_div {
    border-bottom: 1px dashed white;
    display: block;
    font-weight: bold;
    margin-top: 5px;
  }
</style>