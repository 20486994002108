<template>
<div v-if="active">
  <slot name="morefilters"></slot>
  <div class="filterComponent d-flex align-items-center justify-content-center">
      <div class="infoTip" v-if="info"><img src="/img/info.png" alt="" title="Informe o filtro desejado e clique no botão [Buscar]"></div>
      <div class="left-gap" v-else></div>
      <slot name="filter"></slot>
  </div>
</div> 
</template>

<script>
import toggleFilterStorage from '@/toggleFilterStorage_mixins.js'
import selectOptionsStorage from '@/selectOptionsStorage_mixins.js'
import barramento from '@/barramento'
export default {
    props: {
        id: String, 
        info: {default: true} 
    },
    mixins:[toggleFilterStorage,selectOptionsStorage],
    data() {
        return {
            active: false,
            storageKey: 'activeFilterButton'
        }
    },
    created() {
        this.toggle()
        barramento.$on('filterChanged', ()=> {
            this.toggle()
        })
        barramento.$on('toggleFilterStorageRemoveAll', ()=> {
            this.active = false
        })
    },
    methods: {
        toggle() {
            if (this.checkIfExists(this.storageKey,this.id) === true) {
                this.active = true
            } else {
                this.active = false
                //remove all tags selected from a filter
                this.removeAlTagsFromKey(this.id)
            }
        }
    }
}
</script>

<style>
    .filterComponent {
        width: 100%;
        /* height: 40px; */
        height: auto;
        margin-top: 30px;
    }
    .infoTip {
        margin-right: 5px;
        margin-left: 15px;
    }
    .left-gap {
        margin-right: 5px;
        margin-left: 30px;
    }
</style>