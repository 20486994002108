<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>
    <div v-else>
        <div class="info t-14 grey-text">Presença em lista nacional oficial de espécies ameaçadas de extinção:</div>
        <div class="session-gap"></div>
        <div class="d-flex justify-content-center" style="max-height: 300px; overflow-y: auto;">
            <table class="table-ficha">
                <tr>
                    <th :class="'th-'+color">Tipo</th>
                    <th :class="'th-'+color">Ano</th>
                    <th :class="'th-'+color">Abrangência </th>
                    <th :class="'th-'+color">Categoria </th>
                    <th :class="'th-'+color">Critério</th>
                    <th :class="'th-'+color">Referência Bibliográfica</th>
                </tr>
                <tr v-for="(item, index) in items.historyTable" :key="index">
                    <td><span v-html="item.type"></span></td>
                    <td><span v-html="item.year"></span></td>
                    <td><span v-html="item.coverage"></span></td>
                    <td><span v-html="item.category"></span></td>
                    <td><span v-html="item.criteria"></span></td>

                    <td v-if="item.refBibs.length > 0">
                        <span v-for="(item2, index2) in item.refBibs" :key="index2">
                            <span v-html="item2.label"></span><br>
                        </span>
                    </td>
                    <td v-else>--</td>
                </tr>
            </table>
        </div>
        <div class="session-gap"></div>
        <div class="info t-14 grey-text presenca d-flex justify-content-center"><b>Presença em UC</b></div>
        <div class="session-gap"></div>
        <div class="d-flex justify-content-center">
            <table class="table-ficha">
                <tr>
                    <th :class="'th-'+color">UC</th>
                    <th></th>
                    <th :class="'th-'+color">Referência Bibliográfica</th>
                </tr>
                <tr v-for="(item, index) in items.UCPresenceTable" :key="index">
                    <td><span v-html="item.name"></span></td>
                    <td></td>
                    <td v-if="item.refBibs.length > 0"><span v-html="item.refBibs[0].label"></span></td>
                    <td v-else>--</td>
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    components:{
        LoadingAjax
    },
    props: ['fichaId','color'],
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){

        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-conserv" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=conservation'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>
    .th-LC {
        background-color: #60C65835 !important;
    }
    .th-CR {
        background-color: #D81E0635;
    }
    .th-EN {
        background-color: #FC7F4035;
    }
    .th-VU {
        background-color: #F7E61135;
        color: black !important;
    }
    .th-DD {
        background-color: #D1D1C635;
        color: black !important;

    }
    .th-RE {
        background-color: #9B4F9635;
    }
    .th-EX {
        background-color: #00000035;
    }
    .th-RE {
        background-color: #54224435;
    }
    .th-NT {
        background-color: #CCE22535;
        color: black !important;
    }
    .th-NA {
        background-color: #C1B5A535;
        color: black !important;
    }
</style>