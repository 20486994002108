<template>
  <div>
    <loading-ajax v-if="loading"></loading-ajax>    
    <div v-else>
        <div class="info t-14 grey-text"  v-if="items.endemicOfBrazil != ''"><b>Endêmica do Brasil?</b> {{items.endemicOfBrazil}}</div>
                        
        <div class="session-gap"></div>
        <div class="session-gap"></div>

        <div class="info t-14 grey-text"  v-if="items.globalDistribution != ''"><b>Distribuição Global:</b><br/><p v-html="items.globalDistribution"></p></div>
        <div class="info t-14 grey-text"  v-if="items.nationalDistribution != ''"><b>Distribuição Nacional:</b><br/><p v-html="items.nationalDistribution"></p></div>

        
        <div class="session-gap"></div>
        <div class="session-gap"></div>

        <div class="info t-14 grey-text"  v-if="items.states != ''"><b>Estados:</b> {{items.states}}</div>
        <div class="info t-14 grey-text"  v-if="items.biomes != ''"><b>Biomas:</b> {{items.biomes}}</div>
        <div class="info t-14 grey-text"  v-if="items.watersheds != ''"><b>Bacias hidrográficas:</b> {{items.watersheds}}</div>
        <div class="session-gap"></div>
        <div class="session-gap"></div>

        <div class="legend-bar fullWidthCollapse d-flex align-items-center justify-content-center">
            <div class="t-10 m-2 white-text">Mapa de distribuição - Imagem</div>
        </div>
    </div>
    <div v-if="items.distributionMap" class="mapa fullWidthCollapse d-flex align-items-center justify-content-center">
        <img :src="items.distributionMap.image" alt="" srcset="">
    </div>
    <div class="mapa fullWidthCollapse d-flex align-items-center justify-content-center mb-5 pb-5">
        <MapContainer :fichaId="this.fichaId"></MapContainer>
    </div>
  </div>
</template>

<script>
import MapContainer from '@/components/fichaComponents/OpenLayerMap.vue'
import api from '@/services/api.js'
import LoadingAjax from '@/components/globalComponents/Loading.vue'

export default {
    props: ['fichaId'],
    components: {
        MapContainer,
        LoadingAjax
    },
    data(){
        return {
            items: [],
            loading: true
        }
    },
    created(){
       
        this.$root.$on(
        "bv::collapse::state",
        function (collapseId, isJustShown) {
            if (collapseId == "collapse-distr" && isJustShown) {
                var apiUrl = '/fichaHtml?idFicha=' + this.fichaId + '&section=distribution'
                api.get(apiUrl).then(response => (
                    this.items = response.data.data
                )).finally(() => this.loading = false)
            }
        }.bind(this)
        );
    }
}
</script>

<style>

</style>