<template>
<div>
    <div class="m-4" v-if="loading">
        <loading-ajax v-if="loading"></loading-ajax>
    </div>
    <div v-if="!this.loading">
        <div class="row align-items-center justify-content-center px-3">
            <div class="col-5 col-md-3 title">Avaliação do Risco de Extinção</div>
            <div class="col-7 col-md 9 name" v-html="items.header.cientificName"></div>
        </div>
        <div class="session-gap"></div>
        <div class="session-gap"></div>
        <div class="autor p-3"><b>Autoria: </b>{{ items.header.authorship }}</div>
        <div class="session-gap"></div>

        <div class="autor p-3"><b>Como citar: </b><br>
            <span v-html="citation"></span>
        </div>

        <!--
        <div class="session-gap"></div>
        <div class="doi p-3" v-if="items.header.DOI">
            <b><i>Digital Object Identifier</i> (DOI): </b> <a v-bind:href="'https://doi.org/'+items.header.DOI" title="Link para o DOI">{{ items.header.DOI }}</a>
        </div>
        -->

        <div class="session-gap"></div>
        <div class="session-gap"></div>
        <div v-if="Object.keys(items.header.photo).length > 0" class="row mx-0" :class="color">
            <div class="col-md-6 col-12 image1 px-0">
                <img :src="items.header.photo.url" alt="Foto" :title="'Crédito: '+items.header.photo.authorName" />

                <div class="" v-if="Object.keys(items.header.photo).length > 0">
                    <div class="t-10 m-2 black-text">
                        Crédito: {{ items.header.photo.authorName }}
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-12 text1 p-4">
                <div class="light t-20">Categoria:</div>
                <div class="t-20"><b v-html="items.header.category"></b></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div class="bold t-20">Última avaliação: <span class="light" v-html="items.header.lastAvaliationDate"></span></div>
                <div class="bold t-20">Ano da publicação: <span class="light" v-html="items.header.publicationYear"></span></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div class="justificativa light t-14" v-html="items.header.justificative"></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>

                <!-- Remoção temporaria solicitada pela equipe em 07/jun/2022 devido a regras de lançamento do Salve Publico-->
                <!--
                <div class="legend t-10">{{ items.header.disclaimer }}</div>
                -->
            </div>
        </div>
        <div v-else class="d-flex about fullWidth justify-content-between" :class="color">
            <div class="text2 p-4">
                <div class="light t-20">Categoria:</div>
                <div class="t-20"><b v-html="items.header.category"></b></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div class="bold t-20">Última avaliação: <span class="light" v-html="items.header.lastAvaliationDate"></span></div>
                <div class="bold t-20">Ano da publicação: <span class="light" v-html="items.header.publicationYear"></span></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div
                class="justificativa light t-14"
                v-html="items.header.justificative"
                ></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <div class="session-gap"></div>

                <!-- Remoção temporaria solicitada pela equipe em 07/jun/2022 devido a regras de lançamento do Salve Publico-->
                <!--
                <div class="legend t-10">{{ items.header.disclaimer }}</div>
                -->
            </div>
        </div>
        <!--
        <div class="image-legend fullWidth d-flex align-items-center" v-if="Object.keys(items.header.photo).length > 0">
            <i class="fas fa-camera"></i>
            <div class="t-10 m-2 black-text">
                Crédito: {{ items.header.photo.authorName }}
            </div>
        </div>
        -->
    </div>
</div>
</template>
<script>
import api from '@/services/api.js'
import barramento from "@/barramento";

export default {
    props: ['fichaId'],
    watch: {
        selfFichaId() {
            this.getInfos();
        },
    },
    data(){
        return {
            items: [],
            loading: true,
            selfFichaId: "",
            color:"",
            citation: '',
        }
    },
    async created() {
        this.selfFichaId = this.fichaId
    },
    methods: {
        async getInfos() {
            var t = this;
            var apiUrl = "/fichaHtml?idFicha=" + this.selfFichaId;
            api.get(apiUrl + "&section=header").then((response) =>
                (t.items["header"] = response.data.data,
                t.citation = response.data.data['citation'])
            ).finally(function (){
                    this.loading = false;this.colorString()
                }.bind(this)
            ).catch((error) => {
                barramento.$emit('closeModalFicha')
                console.log(error)
                alert('Ficha não Publicada')
            });
        },
        colorString() {
            var text = this.items.header.category;
            var pos1 = text.indexOf("(") + 1;
            var pos2 = text.indexOf("(") + 3;
            var final = text.slice(pos1, pos2);

            this.color = final;
            barramento.$emit("color",final)
        }
    }
}
</script>