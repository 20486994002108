<template>
  <div style="width: 100%; height: 600px; margin: auto" class="mapa">
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content"></div>
      <div id="popup-local"></div>

    </div>
    <div ref="map-root" style="width: 100%; height: 100%">
      <Filters @downloadPNG="downloadPNG" @filter="filter"></Filters>
    </div>
    <div class="row">
      <div class="col-12 text-center mt-2">
        <span class="more"
          >O mapa online pode conter registros distintos daqueles da imagem do
          mapa anexado a ficha.</span
        >
      </div>
      <div class="col-12 text-center mb-5">
        <button class="saibaMaisMap" @click="openModal">Saiba mais</button>
      </div>
    </div>
  </div>
</template>
<script>
import Shp from "shpjs";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import { Stroke, Fill, Style, Circle, Icon } from "ol/style";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import {
  ZoomSlider,
  ScaleLine,
  Attribution,
  FullScreen,
  defaults as defaultControls,
} from "ol/control";
import MousePosition from "ol/control/MousePosition";
import Geocoder from "ol-geocoder";
import Filters from "./OpenLayerMapFilters";
import "ol-geocoder/dist/ol-geocoder.min.css";
import { format } from "ol/coordinate";
import { fromLonLat,transform } from "ol/proj";
import {toStringHDMS} from 'ol/coordinate';
import Overlay from 'ol/Overlay';
import "ol/ol.css";
import barramento from "@/barramento";

export default {
  name: "MapContainer",
  components: { Filters },
  props: ["fichaId"],
  data() {
    return {
      map: Object,
      ocorrenciasJson: []
    };
  },
  methods: {
    async getInfoOcorrencia(bd, id){
          var urlSalve = '/salve/';
          var t = this
          let retorno = await t.$axios.get(urlSalve+'api/getInfoOcorrencia?idRegistro='+id+'&noBaseDados='+bd);
          return retorno;
    },

    async loadOcorrencias(){
      var t = this
      //let retorno = await t.$axios.get('http://localhost:8080/salve/api/camadasOcorrenciasModuloPublico/'+this.fichaId);
      let retorno = await t.$axios.get('/salve/api/camadasOcorrenciasModuloPublico/'+this.fichaId);
      this.ocorrenciasJson = this.getGeojsonValidFeatures(retorno.data)
      t.refreshLayerOccurrences()
    },

    getLayerById(id) {
        var layer;
        this.map.getLayers().forEach(function (lyr) {
            if (id == lyr.get('id')) {
                layer = lyr;
            }
        });
        return layer;
    },

    refreshLayerOccurrences(){
        var t = this
        this.ocorrenciasJson.map(function( feature ) {
                var noLayer = feature.get('noLayer');
                let layer = t.getLayerById(noLayer)
                if(layer){
                  layer.getSource().addFeature(feature)
                }
        })
    },

    getGeojsonValidFeatures(geojson) {
      try {
        var features = new GeoJSON().readFeatures(geojson, {
          featureProjection: "EPSG:3857",
        });
        features = features.filter(function (feature) {
          try {
            var geometry = feature.getGeometry();
            if (geometry.getType() == "Point") {
              var coords = geometry.getCoordinates();
              if (
                isNaN(coords[1]) ||
                isNaN(coords[0]) ||
                Math.abs(coords[0]) === Infinity
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } catch (e) {
            return false;
          }
        });
        return features;
      } catch (e) {
        return null;
      }
    },
    filter(layer) {
      this.map
        .getLayers()
        .getArray()
        .forEach(function (e) {
          var name = e.get("name");
          if (e.get("type") == "map_layer") {
            if (
              layer == "streets" ||
              layer == "topography-world" ||
              layer == "national-geography" ||
              layer == "satellite"
            ) {
              if (name == layer) {
                e.setVisible(true);
              } else {
                e.setVisible(false);
              }
            }
          } else {
            if (name == layer) {
              if (e.getVisible()) {
                e.setVisible(false);
              } else {
                e.setVisible(true);
              }
            }
          }
        });
    },
    downloadPNG() {
      this.map.once(
        "rendercomplete",
        function () {
          var mapCanvas = document.createElement("canvas");
          var size = this.map.getSize();
          mapCanvas.width = size[0];
          mapCanvas.height = size[1];
          var mapContext = mapCanvas.getContext("2d");
          Array.prototype.forEach.call(
            document.querySelectorAll(".ol-layer canvas"),
            function (canvas) {
              if (canvas.width > 0) {
                var opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                var transform = canvas.style.transform;
                // Get the transform parameters from the style's transform matrix
                var matrix = transform
                  .match(/^matrix\(([^(]*)\)$/)[1]
                  .split(",")
                  .map(Number);
                // Apply the transform to the export map context
                CanvasRenderingContext2D.prototype.setTransform.apply(
                  mapContext,
                  matrix
                );
                mapContext.drawImage(canvas, 0, 0);
              }
            }
          );
          if (navigator.msSaveBlob) {
            // link download attribuute does not work on MS browsers
            navigator.msSaveBlob(mapCanvas.msToBlob(), "map.png");
          } else {
            var a = document.createElement("a"); //Create <a>
            a.href = mapCanvas.toDataURL(); //Image Base64 Goes here
            a.download = "Mapa.png"; //File name Here
            a.click(); //Downloaded file
          }
        }.bind(this)
      );
      this.map.renderSync();
    },
    openModal() {
      barramento.$emit("modal_saiba_mais_mapa");
    },
  },
  mounted() {
    var geocoder = new Geocoder("nominatim", {
      provider: "osm",
      lang: "pt-BR", //en-US, fr-FR
      countrycodes: "br",
      placeholder: "Informe a localidade e pressione <Enter>.",
      targetType: "glass-button",
      limit: 15,
      keepOpen: false,
      preventDefault: true, // não criar a marker padrão
    });

    // Batimetria
    const urlBatimetria = "/salve/api/shapefile/batimetria";
    const batimetriaLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          //color: 'rgba(65,160,210, 0.8)', // azul piscina
          color: "#2e79ba", // azul piscina
          width: 1,
        }),
      }),
      visible: false,
      id: "Batimetria",
      name: "batimetria",
      type: "vector",
      edit: false,
      zIndex: 1,
    });

    Shp(urlBatimetria).then(function (geojson) {
      var features = this.getGeojsonValidFeatures(geojson);
      batimetriaLayer.setSource(
        new VectorSource({
          features: features,
        })
      );
    });

    var container = document.getElementById('popup');
    var content = document.getElementById('popup-content');
    var local = document.getElementById('popup-local');
    var closer = document.getElementById('popup-closer');

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    var overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    // this is where we create the OpenLayers map
    var map = new Map({
      controls: defaultControls({ attribution: false }).extend([
        new FullScreen(),
        new ZoomSlider(),
        new ScaleLine(),
        new MousePosition({
          projection: "EPSG:4326",
          coordinateFormat: function (coordinate) {
            return format(coordinate, "y {y}, x {x}", 6);
          },
          undefinedHTML: "Lat:? Lon:?",
        }),
        new Attribution({ collapsible: true, collapsed: true }),
        geocoder,
      ]),
      // the map will be created using the 'map-root' ref
      target: this.$refs["map-root"],
      overlays: [overlay],
      layers: [
        // Default Open Street Map / Ruas
        new TileLayer({
          source: new OSM(),
          type: "map_layer",
          name: "streets",
        }),
        // Topografia mundial
        new TileLayer({
          source: new XYZ({
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "map_layer",
          name: "topography-world",
        }),
        // Geografia Nacional
        new TileLayer({
          source: new XYZ({
            url: "https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "map_layer",
          name: "national-geography",
        }),
        // Satélite
        new TileLayer({
          source: new XYZ({
            url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          type: "map_layer",
          visible: false,
          name: "satellite",
        }),
        // // Biomas
        new TileLayer({
          source: new TileWMS({
            url: "http://mapas.icmbio.gov.br/cgi-bin/mapserv?map=/geodados/icmbio/mapfile/biomas.map&version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png",
            params: { LAYERS: "biomas" },
            serverType: "mapserver",
            crossOrigin: "anonymous",
          }),
          visible: false,
          id: "biomas",
          name: "biomes",
          type: "wms",
          zIndex: 1,
        }),

        // UC Federal
        new TileLayer({
          source: new TileWMS({
            url: "http://mapas.icmbio.gov.br/mapproxy/service",
            params: {
              LAYERS: "uc",
              SERVICE: "WMS",
              VERSION: "1.3.0",
              STYLES: "",
            },
            serverType: "mapserver",
            crossOrigin: "anonymous",
          }),
          visible: false,
          id: "uc_federal",
          name: "uc_federal",
          type: "wms",
          zIndex: 1,
        }),
        // // UC Estadual
        new TileLayer({
          source: new TileWMS({
            url: "http://mapas.icmbio.gov.br/cgi-bin/mapserv?map=/geodados/icmbio/mapfile/mma_UcEstadual.map&version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png",
            params: { LAYERS: "uc_estadual" },
            crossOrigin: "anonymous",
          }),
          visible: false,
          id: "uc_estadual",
          name: "uc_estadual",
          type: "wms",
          zIndex: 1,
        }),
        // // ZEE
        new TileLayer({
          source: new TileWMS({
            url: "http://mapas.icmbio.gov.br/cgi-bin/mapserv?map=/geodados/icmbio/mapfile/ibge_zee.map&version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png",
            params: { 'LAYERS': "zee" },
            serverType: "mapserver",
            crossOrigin: "anonymous",
          }),
          visible: false,
          id: "zee",
          name: "zee",
          type: "wms",
          zIndex: 1,
        }),
        // Batimetria
        batimetriaLayer,

        // regUtilizadoHistorico
        new VectorLayer({
                    source: new VectorSource({
                       format: new GeoJSON(),
                    }),
                    visible: true,
                    id: "regUtilizadoHistorico",
                    name: "regUtilizadoHistorico",
                    type: "vector",
                    zIndex: 5,
                    style: new Style({
                      image: new Icon({
                        src: '/salve/assets/markers/ponto_verde_12x12_T.png',
                        anchor: [0.5, 1]
                      }),
                    }),
                }),


        // regUtilizados
        new VectorLayer({
                    source: new VectorSource({
                       format: new GeoJSON(),
                    }),
                    visible: true,
                    id: "regUtilizados",
                    name: "regUtilizados",
                    type: "vector",
                    zIndex: 5,
                    style: new Style({
                      image: new Circle({
                        radius: 5,
                        stroke: new Stroke({ color: "#000000", width: 0.5 }),
                        fill: new Fill({ color: "rgba(0, 255, 0, 1)" }),
                      }),
                    }),
                }),

        // regConfirmadoAdicionadoAposAvaliacao
        new VectorLayer({
                    source: new VectorSource({
                       format: new GeoJSON(),
                    }),
                    visible: true,
                    id: "regConfirmadoAdicionadoAposAvaliacao",
                    name: "regConfirmadoAdicionadoAposAvaliacao",
                    type: "vector",
                    zIndex: 5,
                    style: new Style({
                        image: new Icon({
                        src: '/salve/assets/markers/ponto_verde_12x12_X.png',
                        anchor: [0.5, 1]
                        }),

                      }),
                }),


        /*
        // Portalbio (utilizadas)
        new VectorLayer({
          source: new VectorSource({
              //url: "/salve/api/ocorrenciasModuloPublico/-123765/" +this.fichaId +"/geojson/portalbio/%23FFFF00/s",
              url: "http://localhost:8080/salve/api/ocorrenciasModuloPublico/-123765/" +this.fichaId +"/geojson/portalbio/%23FFFF00/s",
              format: new GeoJSON(),
              crossOrigin: "anonymous",
          }),
          visible: true,
          id: 'regUtilizadoHistorico',
          name: "portalbio-utilizadas",
          type: 'vector',
          zIndex: 5,
          style: new Style({
            image: new Circle({
              radius: 5,
              stroke: new Stroke({ color: "#000000", width: 0.5 }),
              fill: new Fill({ color: "rgba(255,255,0,1)" }),
            }),
          }),
        }),
        */

      ],

      // the map view will initially show Brasl
      view: new View({
        zoom: 4,
        center: fromLonLat([-51.92528, -14.235004]),
        constrainResolution: true,
      }),
    });

    // var highlight;
    var t = this;
    var displayFeatureInfo = function (pixel) {
      var feature = map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      });

      if (feature != undefined) {
        var info = document.getElementById('popup');
        if (feature) {

          // transformar para EPSG:4326
          var coordinate = transform( feature.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326');

          var hdms = toStringHDMS(coordinate);

          content.innerHTML = 'Obtendo informações...';
          local.innerHTML = '<code>' + hdms + '</code>';

          t.getInfoOcorrencia(feature.values_.bd, feature.values_.id).then(
            function(value) {
              if(value.data.text){
                content.innerHTML = value.data.text;
              }else{
                content.innerHTML = 'Ops! Nenhuma informação encontrada!';
              }
            },
            function(error) {
              let msgErro = 'Ocorreu um erro ao tentar obter informações. Favor tentar mais tarde. Detalhe do erro: '+error;
              content.innerHTML = msgErro;
            }
          );

          overlay.setPosition(coordinate);


        } else {
          info.innerHTML = '&nbsp;';
        }
      }
    };

    map.on('click', function (evt) {
      displayFeatureInfo(evt.pixel);
    });


    // ao selecionar uma localidade
    geocoder.on("addresschosen", function (evt) {
      map.getView().setCenter(evt.coordinate);
      map.getView().setZoom(12);
    });

    //passa para filtros:
    this.map = map;
    //Refresh map when collapsed
    this.$root.$on(
      "bv::collapse::state",
      function (collapseId, isJustShown) {
        if (collapseId == "collapse-distr" && isJustShown) {
          window.setTimeout(
            function () {
              this.map.updateSize();
            }.bind(this),
            1000
          );
        }
      }.bind(this)
    );

    // carrega as ocorencias
    this.loadOcorrencias();

  },
};
</script>
<style lang="scss">
.ol-overlaycontainer-stopevent {
  z-index:9999999999999999 !important;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  font-size: 10px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
.ol-geocoder.gcd-gl-container {
  top: 20.275em !important;
}
.ol-mouse-position {
  padding: 3px;
  font-size: 16px;
  background-color: rgb(124, 153, 189);
  color: rgb(255, 255, 255);
  min-width: 140px;
  max-height: 30px;
  border-radius: 4px;
  text-align: center;
  inset: 10px 47px auto auto;
  opacity: 0.7;
}
.more {
  font-size: 0.7rem;
  line-height: 0.7;
  text-align: center;
  color: #505050;
}
.saibaMaisMap {
  border-radius: 17px;
  border: none;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.33;
  color: #282828;
  margin: auto;
  background-color: #e2f5a1;
  padding: 9px 19px 9px 20px;
}
</style>
